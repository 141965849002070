/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { defineStore } from 'pinia';

/** Models */
import { ModelHistoryData } from '@/models/ModelHistory';
import { ModelViewDefinition } from '@/models/ModelViewDefinition';
import { CustomFieldValuesArray, SelectedCustomField, ProjectModificationData } from '@/models/Project';
import {
  ModelProject, ModelProjectViewData, ModelScore, ModelLockState, ModelMilestone,
} from '@/models/Model';

/** Utils */
import { getDateFromDiff } from '@/utils/date.util';

/** Store */
import { useQueueStore } from '@/store/queue';
import { useHandsontableStore } from './handsontable';

export const useModelStore = defineStore('model', {
  state: () => ({
    modelLoading: false,
    viewDefinition: {} as ModelViewDefinition,
    projects: [] as ModelProjectViewData[],
    projectCustomFieldValues: [] as CustomFieldValuesArray[],
    virtualProjects: [] as ModelProjectViewData[],
    selectedCustomFields: [] as SelectedCustomField[],
    selectedSubHeaderTab: 0,
    virtualResources: [] as any[], // TODO: import the type from old codebase
    milestones: [] as ModelMilestone[],
    history: [] as ModelHistoryData[],
    consolidatedHistory: [] as ModelHistoryData[],
    historyLinks: [] as ModelHistoryData[],
    score: {} as ModelScore,
    lockState: {} as ModelLockState,

    selectedTimelineProjectsIDs: [] as number[],
    selectedResourceProjectsIDs: [] as number[],

    searchFilterResources: '',
    topResizeRowHeight: 350,
    leftResizeColWidth: 200,
  }),

  actions: {
    /**
     * Set loading flag to for model Common Loader
     * @param loadingFlag
     */
    setModelLoading(loadingFlag: boolean) {
      this.modelLoading = loadingFlag;
    },

    /**
     * Set view definition
     * @param viewDefinition
     */
    setViewDefinition(viewDefinition: ModelViewDefinition) {
      this.viewDefinition = viewDefinition;
    },

    /**
     * Set projects
     * @param {ModelProject[]} projects
     */
    setProjects(projects: ModelProject[]) {
      this.projects = projects.map((project) => ({
        ...project,
        StartDate: getDateFromDiff(project.StartDate),
        EndDate: getDateFromDiff(project.EndDate),
      }));
    },

    /**
     * Set project custom field values
     * @param {CustomFieldValuesArray[]} values
     */
    setProjectCustomFieldValues(values: CustomFieldValuesArray[]) {
      this.projectCustomFieldValues = values;
    },

    /**
     * Set virtual projects
     * @param {ModelProject[]} projects
     */
    setVirtualProjects(projects: ModelProject[]) {
      this.virtualProjects = projects;
    },

    /**
     * Set selected custom fields
     * @param {SelectedCustomField[]} fields
     */
    setSelectedCustomFields(fields: SelectedCustomField[]) {
      this.selectedCustomFields = fields;
    },

    /**
     * Set selected custom fields
     * @param {number} value
     */
    setSelectedSubHeaderTab(value: number) {
      this.selectedSubHeaderTab = value;
    },

    /**
     * Set virtual resources
     * @param {any[]} resources
     */
    setVirtualResources(resources: any[]) {
      this.virtualResources = resources;
    },

    /**
     * Set milestones
     * @param {ModelMilestone[]} milestones
     */
    setMilestones(milestones: ModelMilestone[]) {
      this.milestones = milestones;
    },

    /**
     * Set history
     * @param {ModelHistoryData[]} history
     */
    setHistory(history: ModelHistoryData[]) {
      this.history = history;
    },

    /**
     * Set consolidated history
     * @param {ModelHistoryData[]} history
     */
    setConsolidatedHistory(history: ModelHistoryData[]) {
      this.consolidatedHistory = history;
    },

    /**
     * Set history links
     * @param {ModelHistoryData[]} links
     */
    setHistoryLinks(links: ModelHistoryData[]) {
      this.historyLinks = links;
    },

    /**
     * Set model score
     * @param {ModelScore} score
     */
    setModelScore(score: ModelScore) {
      this.score = score;
    },

    /**
     * Set model lock state
     * @param {ModelLockState} lockState
     */
    setModelLockState(lockState: ModelLockState) {
      this.lockState = lockState;
    },

    setSelectedTimelineProjects(ids: number[]) {
      this.selectedTimelineProjectsIDs = ids;
    },

    setSelectedResourceProjects(ids: number[]) {
      this.selectedTimelineProjectsIDs = ids;
    },

    /**
     * Update project
     * @param id
     * @param newStartDate
     * @param newEndDate
     * @param modificationData
     */
    updateProject(
      id: number,
      newStartDate: Date,
      newEndDate: Date,
      modificationData: ProjectModificationData,
    ) {
      this.$patch((state) => {
        state.projects = state.projects.map((project) => {
          if (project.Id === id) {
            return {
              ...project,
              StartDate: newStartDate,
              EndDate: newEndDate,
            };
          }
          return project;
        });
      });
      const queueStore = useQueueStore();
      const handsontableStore = useHandsontableStore();
      queueStore.addGetter('GetModelResourceGrid', { Config: handsontableStore.resourceGridConfig });
      queueStore.addModification('MakeChanges', {
        ModelId: 2,
        Changes: [modificationData],
      });
      queueStore.sendQueue();
    },

    /**
     * Set search filter value
     * @param searchValue
     */
    setSearchFilterResources(searchValue: string) {
      this.searchFilterResources = searchValue;
    },

    /**
     * Set top resizing row height
     * @param height
     */
    setTopResizeRowHeight(height: number): void {
      this.topResizeRowHeight = height || 350;
    },

    /**
     * Set left resizing col width
     * @param width
     */
    setLeftResizeColWidth(width: number): void {
      this.leftResizeColWidth = width || 200;
    },
  },
});
