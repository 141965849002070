import { Interval, DateTime } from 'luxon';
import { DurationUnit } from 'luxon/src/duration';

const INITIAL_DATE = '1904-01-01';

/**
 * Get date from the `diff` (legacy date format) value that's received from API.
 * @param {number} diff
 * @returns {Date}
 */
export const getDateFromDiff = (diff: number) => {
  const date = new Date(INITIAL_DATE);
  date.setDate(date.getDate() + diff);
  return date;
};

/**
 * Get full date & time
 * e.g. 10/6/2022, 10:34:14 PM
 * @param datetime
 */
export const getFullDateTime = (datetime: string) => DateTime
  .fromISO(datetime)
  .toFormat('LL/d/yyyy tt');

/**
 * Get full date & day of week
 * e.g. Fri_Sep_30_2022
 * @param date
 */
export const getDateWithDayOfWeek = (date?: string) => {
  if (!date) {
    return DateTime.now().toFormat('ccc_LLL_dd_yyyy');
  }
  return DateTime.fromISO(date).toFormat('ccc_LLL_dd_yyyy');
};

/**
 * Get date with month
 * e.g. 17 OCT 2022
 * @param date
 */
export const getDateWithMonth = (date?: string) => {
  if (!date) {
    return DateTime.now().toFormat('dd LLL yyyy');
  }
  return DateTime.fromISO(date).toFormat('dd LLL yyyy');
};

/**
 * Get month with year
 * e.g. OCT 22
 * @param date
 * @param format
 */
export const getMonthWithYear = (date?: string, format = 'd LLL yy') => {
  if (!date) {
    return DateTime.now().toFormat('LLL yy');
  }
  return DateTime.fromFormat(date, format).toFormat('LLL yy');
};

/**
 * Get time difference between two dates
 * @param start
 * @param end
 * @param unit years, days, etc.
 */
export const getDateDiff = (start: number, end: number, unit: DurationUnit) => {
  const formattedStart = new Date(getDateFromDiff(start));
  const formattedEnd = new Date(getDateFromDiff(end));
  const diff = Interval.fromDateTimes(formattedStart, formattedEnd);
  return diff.length(unit);
};

/**
 * Get amount of business days between two dates
 * @param start
 * @param end
 */
export const getBusinessDiff = (start: Date | string, end: Date | string) => {
  let count = 0;
  let multiplier = 1;

  const dates = [new Date(start), new Date(end)];
  if (dates[0] > dates[1]) multiplier = -1;
  dates.sort((date1, date2) => date1.getTime() - date2.getTime());

  while (dates[0] <= dates[1]) {
    const dayOfWeek = dates[0].getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count += 1;
    dates[0].setDate(dates[0].getDate() + 1);
  }
  if (count > 0) count -= 1;
  return count * multiplier;
};

/**
 * Generate list with dates by interval
 * @param start
 * @param mode
 * @param format
 * @param i
 */
export const generateDatesListWithInterval = (start: string, mode = 'Month', format = 'dd LLL yy', i = 0): {
  headerCellText: string,
  ISOformat: string,
} => {
  const plusConfig = mode === 'Month' || mode === 'Quarter'
    ? { months: i }
    : { months: 0, days: i };

  const headerCellText = DateTime.fromISO(start)
    .plus(plusConfig)
    .toFormat(format) || '-';

  const ISOformat = DateTime.fromISO(start)
    .plus(plusConfig).toISO();

  return {
    headerCellText,
    ISOformat,
  };
};

/**
 * Check Date for weekend
 * @param date
 */
export const isWeekend = (date: string): boolean => {
  const formatDate = DateTime.fromISO(date);
  const { weekday } = formatDate;

  const weekends = [6, 7];

  return weekends.includes(weekday);
};

/**
 * Check Date for weekend
 * @param date
 */
export const isDayBeforeWeekend = (date: string): boolean => {
  const formatDate = DateTime.fromISO(date);
  const { weekday } = formatDate;

  return weekday === 5;
};

/**
 * Get Date quarter
 * @param date
 */
export const getQuarter = (date: string): (string | number) => DateTime.fromISO(date).quarter;

/**
 * Get Date month
 * @param date
 */
export const getMonth = (date: string): number => DateTime.fromISO(date).month;

/**
 * Get Date week in year
 * @param date
 */
export const getWeekInYear = (date: string): number => DateTime.fromISO(date).weekNumber;
