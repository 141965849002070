
import Vue from 'vue';
import Component from 'vue-class-component';

import { mapState } from 'pinia';
import { useModelStore } from '@/store/model';
import { ModelScore } from '@/models/ModelScore';
import HeaderWrapperHistory from './HeaderWrapperHistory.vue';

@Component({
  components: {
    HeaderWrapperHistory,
  },
  computed: {
    ...mapState(useModelStore, ['score']),
  },
})
export default class HeaderWrapper extends Vue {
  score!: ModelScore[];

  $refs!: {
    historyPopup: HTMLDivElement,
  }

  get isOnModelListPage() {
    return this.$route.name === 'modelList';
  }

  get isOnModelDetailsPage() {
    return this.$route.name === 'modelDetails';
  }

  /**
    * Go to current model list page
    */
  goToModelListPage(): void {
    this.$router.push('/model-list');
  }

  async handleHistoryActivatorClick() {
    if (this.$refs.historyPopup) {
      await this.$refs.historyPopup.fetchMainInfo();
    }
  }
}
