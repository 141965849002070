
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  /** Overlay visibility */
  props: {
    overlay: {
      default: false,
      required: true,
      type: Boolean,
    },

    /** Overlay position relatively parent component */
    absolute: {
      default: true,
      required: false,
      type: Boolean,
    },

    /** Overlay z-index */
    zIndex: {
      default: 5,
      required: false,
      type: Number,
    },

    progressCircularSize: {
      default: 64,
      required: false,
      type: [Number, String],
    },
  },
})
export default class Loader extends Vue {
}
