import _ from 'lodash';

export const decompress = (row: number[] | Float32Array/* ,isFte */) => {
  let i = 0;
  let repeater;
  const result: string[] = [];
  let element;

  if (!_.isArray(row)) {
    throw new Error('An error occurred during data decompression.');
  }
  while (i < row.length) {
    repeater = row[i++]; // eslint-disable-line no-plusplus
    if (repeater > 0) {
      if (i === row.length) {
        throw new Error('An error occurred during data decompression.');
      }
      element = row[i];
      // if (isFte && _.isNull(element)) {
      // element = -1;
      // }
      Array.prototype.push.apply(result, _.fill(new Array(repeater), element));
      i += 1;
    } else if (repeater < 0) {
      if (i - repeater > row.length) {
        throw new Error('An error occurred during data decompression.');
      }
      element = row.slice(i, i - repeater);
      // if (isFte) {
      // element = _.map(element, fixFteArray);
      // }
      Array.prototype.push.apply(result, element);
      i += -repeater;
    } else {
      throw new Error('An error occurred during data decompression.');
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new Float32Array(result);
};
