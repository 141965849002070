import { defineStore } from 'pinia';

/** Models */
import {
  ModelResourceGrid,
  ModelResourceGridItem,
  ModelResourceGridConfig,
  ResourceGridDeltaConfig,
  ResourceGridExpandedResourceType,
  ResourceGridMode,
  ResourceGridTimeUnit,
  ResourceGridValuesMode,
} from '@/models/ModelResource';

/** Utils */
import { decompress } from '@/utils/resource.util';
import { formatNumber } from '@/utils/number.util';

/** Store */
import { useModelStore } from '@/store/model'; // eslint-disable-line import/no-cycle
import { useResourceStore } from './resources';
import { useStaticStore } from './static';
import { useProjectStore } from './projects';

/**
 * Insert children with resources { r: number } to appropriate index & type by parent
 * @param typeList
 * @param finalList
 * @param children
 */
const insertResourceChildren = (
  typeList: string[],
  finalList: ModelResourceGrid[],
  children: any,
): void => {
  typeList.forEach((type) => {
    const childrenByType = children
      .filter((child: any) => child.t?.every((v: any) => type.includes(v)))
      .sort((a: any, b: any) => a.name?.localeCompare(b.name));
    const indexToUnshift = finalList
      .findIndex((item) => item.t?.every((v) => type.includes(v)));
    finalList.splice(indexToUnshift + 2, 0, ...childrenByType);
  });
};

/**
 * Format resource grid data
 * @param list
 * @param mode
 * @param filterByNetAvailability
 */
const getGridDataBy = (
  list: any,
  mode: string | null,
  filterByNetAvailability: boolean,
) => list
  .map((resource: ModelResourceGrid) => {
    const { Allocation } = resource;
    const { AllocationBefore } = resource;
    const { Capacity } = resource;
    const { CapacityBefore } = resource;

    let resourceModeList!: number[] | Float32Array;

    if (mode === 'allocation') resourceModeList = Allocation;
    if (mode === 'capacity') resourceModeList = Capacity;
    if (mode === 'allocationBefore') resourceModeList = AllocationBefore;
    if (mode === 'capacityBefore') resourceModeList = CapacityBefore;

    if (filterByNetAvailability) {
      return Capacity.map((el: number, index: number) => (
        Allocation[index]
          ? Number(formatNumber(+el - Allocation[index]))
          : Number(formatNumber(+el))
      ));
    }

    return (resourceModeList || []).map((el: number) => (el % 1 === 0
      ? el || 0
      : (((`${el.toFixed(2)}`).slice(-2) === '00' && Math.round(el))
          || Number(el.toFixed(2)))));
  });

export const useHandsontableStore = defineStore('handsontable', {
  state: () => ({
    resourceGrid: [] as ModelResourceGrid[],
    decompressResourceGrid: [] as ModelResourceGrid[],
    formattedResourceGrid: [] as ModelResourceGrid[],
    filteredResourceGridItems: [] as ModelResourceGrid[],

    filteredResourceGrid: [] as ModelResourceGrid[],
    hotSettingsData: [] as number[][],

    handsonTableColHeaders: [] as { label: string, colspan: number }[][],
    resourcesSelectedTab: 'heat_map',

    // selected filters value
    selectedAvailability: 'availability',
    selectedPeriod: 'Month' as ResourceGridTimeUnit,
    selectedValuesMode: 'Time' as ResourceGridValuesMode,
    selectedLimitAssignedResources: 0,
    selectedGridHeatmapOverlay: 0,

    deltaConfig: null as ResourceGridDeltaConfig | null,
    mode: 'ResourceViewQuickLoad' as ResourceGridMode,
    expandedResourceTypes: null as ResourceGridExpandedResourceType[] | null,
    expandAllResources: false,
    resourceFilteredProjects: [] as number[],
  }),

  getters: {
    hasDeltaViewData: (state) => state.formattedResourceGrid
      .every((item) => item?.AllocationBefore && item?.CapacityBefore),

    resourceGridConfig: (state): ModelResourceGridConfig => {
      const modelStore = useModelStore();
      const projectsStore = useProjectStore();
      const { Id: id } = modelStore.viewDefinition;
      const { resourceVisibleProjects } = projectsStore;

      return {
        ModelId: id,
        TimeUnit: state.selectedPeriod,
        ExpandedResourceTypes: state.expandedResourceTypes,
        ExpandAllResourceTypes: state.expandAllResources,
        ValuesMode: state.selectedValuesMode,
        DeltaConfig: state.deltaConfig,
        ReplaceResourcePreviewConfig: null,
        Mode: state.mode,
        ImpactProjectId: null,
        ChangesPreview: null,
        FilteredProjects: state.resourceFilteredProjects.length
          ? state.resourceFilteredProjects : null,
        VisibleProjects: state.selectedLimitAssignedResources ? resourceVisibleProjects : null,
      };
    },
  },

  actions: {
    /**
     * Set resource grid
     * @param { ModelResourceGrid[] } resourceGrid
     */
    setResourceGrid(resourceGrid: ModelResourceGrid[]) {
      this.resourceGrid = resourceGrid;

      if (!(resourceGrid || []).length) return;

      this.setDecompressResourceGrid(resourceGrid);
    },

    /**
     * Set decompressed resource grid
     * @param { ModelResourceGrid[] } resourceGrid
     */
    setDecompressResourceGrid(resourceGrid: ModelResourceGrid[]) {
      this.decompressResourceGrid = resourceGrid
        .map((item) => {
          const decompressedA = decompress(item.a);
          const decompressedC = decompress(item.c);

          const decompressed = {
            ...item,
            Allocation: decompressedA,
            Capacity: decompressedC,
          };

          const { percentTabs } = useStaticStore();

          if (!percentTabs.includes(this.resourcesSelectedTab)) return decompressed;

          const decompressedB = decompress(item.b);
          const decompressedD = decompress(item.d);

          return {
            ...decompressed,
            AllocationBefore: decompressedB,
            CapacityBefore: decompressedD,
          };
        });

      this.setFormattedResourceGrid();
      this.setFilteredResourceGridItems(this.decompressResourceGrid);
    },

    /**
     * Set formatted resource grid
     */
    setFormattedResourceGrid() {
      // Find & format nested rows with level-3 data
      const children = this.decompressResourceGrid
        .filter((item) => item.r && item.t && item.t.length > 1)
        .map((item) => {
          let resource = useResourceStore().resources
            .find((v) => item.r === v.Id);
          if (!resource) {
            resource = useModelStore().virtualResources
              .find((v) => item.r === v.Id);
          }
          return {
            ...item,
            name: resource?.Name || '',
          };
        });

      // Filter list without child with resource
      const listWithoutChild = this.decompressResourceGrid
        .filter((item) => !item.r);
      const uniqueResourceTypes: string[] = [];
      const additionalUniqueTypes: string[] = [];
      // Find unique resource types
      children.forEach((child) => {
        if (child.t && !child.t?.every((v) => uniqueResourceTypes.flat().includes(v))) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          uniqueResourceTypes.push(child.t);
        }
      });
      // Add sorted level-3 child to list by appropriate index & type
      insertResourceChildren(uniqueResourceTypes, listWithoutChild, children);

      // Check if still some children are not inserted
      const notIncludedList = children.filter((v) => !listWithoutChild
        .find((_v) => _v.r === v.r));

      notIncludedList.forEach((child) => {
        if (child.t && !child.t?.every((v) => additionalUniqueTypes.flat().includes(v))) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          additionalUniqueTypes.push(child.t);
        }
      });

      insertResourceChildren(additionalUniqueTypes, listWithoutChild, children);

      // Already with child
      this.formattedResourceGrid = listWithoutChild;
    },

    /**
     * Format resource grid data by capacity/allocation with/without total values
     * @param mode allocation / capacity / allocationBefore / capacityBefore
     * @param withTotal
     * @param filterByNetAvailability
     */
    getFilteredGridBy(
      mode: string | null,
      withTotal: boolean,
      filterByNetAvailability: boolean,
    ) {
      if ((this.formattedResourceGrid || []).length) {
        const list = getGridDataBy(this.formattedResourceGrid, mode, filterByNetAvailability);

        if (!withTotal) return list;

        const upperList = this.formattedResourceGrid
          .filter((item) => !item.r && item.t?.length === 1);
        const upperListGridData = getGridDataBy(upperList, mode, filterByNetAvailability);
        // Get total values
        const totalList = upperListGridData.reduce((array1: any, array2: any) => array1.map(
          (value: number, index: number) => (
            // TODO: Make it smarter :)
            parseFloat(String(value)) + parseFloat(String(array2[index]))
          ),
        ));

        list.unshift(totalList);

        return list;
      }

      return [];
    },

    /**
     * Set filtered grid items without level-3 items
     */
    setFilteredResourceGridItems(decompressResourceGrid: ModelResourceGrid[]) {
      const filteredresourceGrid = decompressResourceGrid
        .filter((item: ModelResourceGrid) => !item.r);

      const modelStore = useModelStore();

      if (modelStore.searchFilterResources && modelStore.searchFilterResources !== '') {
        this.filteredResourceGridItems = filteredresourceGrid
          .filter((item: ModelResourceGrid) => {
            const text = item?.t?.[0];

            return (text || '')
              .toLowerCase()
              .includes(modelStore.searchFilterResources.toLowerCase());
          });
      }

      this.filteredResourceGridItems = filteredresourceGrid;
    },

    /**
     * Get children items (level-3) for parent item (level-2)
     * @param itemGrid
     */
    getNestedItemsByType(itemGrid: ModelResourceGrid) {
      const itemType = itemGrid?.t || [];
      const isNestedChild = (itemGrid?.t?.length || 0) > 1;

      const modelStore = useModelStore();
      const resourcesStore = useResourceStore();

      const children = this.decompressResourceGrid
        .filter((item: ModelResourceGrid) => item.r && isNestedChild
          && (item?.t || []).every((type) => itemType.includes(type)));

      return children.map((child: ModelResourceGrid) => {
        let resource: {
          Id: number;
          Name: string;
          ResourceKind?: string;
        } | undefined = resourcesStore.resources
          .find((item: {Id: number;}) => child.r === item.Id);

        if (!resource) {
          resource = modelStore.virtualResources
            .find((item: {Id: number;}) => child.r === item.Id) || { Name: null, ResourceKind: '' };
        }

        if (resource) {
          return {
            ...child,
            name: resource.Name,
            ResourceKind: resource.ResourceKind || '',
          };
        }
        return child;
      }).sort((a: any, b: any) => a.name?.localeCompare(b.name));
    },

    /**
     * Get resource grid type
     * @param itemGrid
     */
    getResourceGridType(itemGrid: ModelResourceGridItem): number {
      if (itemGrid.t.length > 1 && !itemGrid.r) {
        return 2;
      }
      if (itemGrid.t.length > 1 && itemGrid.r) {
        return 3;
      }
      return 1;
    },

    /**
     * Get Grid Item Arrow Icon
     * @param index
     * @param item
     */
    getGridItemArrowIcon(index: number, item: ModelResourceGrid): string {
      const isLastItem = index === this.decompressResourceGrid.length - 1;
      if (isLastItem) {
        return 'item-collapsed';
      }
      const nextItem: ModelResourceGrid = this.decompressResourceGrid[index + 1];
      if (
        ((nextItem?.t?.length || 0) > 1 && !nextItem.r)
        || this.getNestedItemsByType(item).length
      ) {
        return 'item-expanded';
      }
      return 'item-collapsed';
    },

    /**
     * Check is the resource virtual
     * @param item
     */
    isVirtualResource(item: ModelResourceGridItem): boolean {
      return item.ResourceKind === 'VirtualNamed';
    },

    /**
     * Format resource grid data by capacity/allocation
     * @param mode allocation / capacity / allocationBefore / capacityBefore
     */
    getMaxLength(mode: string): number {
      let maxValue = 0;

      this.decompressResourceGrid.forEach((item: ModelResourceGrid) => {
        const { Allocation } = item;
        const { AllocationBefore } = item;
        const { Capacity } = item;
        const { CapacityBefore } = item;

        let resourceModeList!: number[] | Float32Array;

        if (mode === 'allocation') resourceModeList = Allocation;
        if (mode === 'capacity') resourceModeList = Capacity;
        if (mode === 'allocationBefore') resourceModeList = AllocationBefore;
        if (mode === 'capacityBefore') resourceModeList = CapacityBefore;

        if (resourceModeList.length > maxValue) {
          maxValue = resourceModeList.length;
        }
      });

      return maxValue;
    },

    /**
     * Generate class with color for Handsontable cells
     * (Heat Map, Cool Map, Delta tabs).
     * @param value Cell value for color generating
     */
    getPercentFillClass(value: number): string[] {
      if (
        ['heat_map', 'delta', 'grid']
          .includes(this.resourcesSelectedTab)
      ) {
        if (value < 0) return ['heat_zero'];
        if (value > 180) return ['heat_negative', `heat_${value}`];
        return [`heat_${value}`];
      }

      if (this.resourcesSelectedTab === 'cool_map') {
        if (value >= 100) return ['cool_negative'];
        if (value < 0) return ['cool_zero'];
        return [`cool_${value}`];
      }

      return ['_'];
    },

    /**
     * Set filtered resource grid
     * @param {ModelResourceGrid[]} resourceGrid
     */
    setFilteredResourceGrid(filteredResourceGrid: ModelResourceGrid[]): void {
      this.filteredResourceGrid = filteredResourceGrid;
    },

    /**
     * Set Handsontable Column Headers (needed in pdf table)
     * @param headers
     */
    setHandsonTableColHeaders(headers: { label: string, colspan: number }[][]) {
      this.handsonTableColHeaders = headers;
    },

    /**
     * Set selected Tab on resource section
     * @param {string} tab
     */
    setResourcesSelectedTab(tab: string) {
      this.resourcesSelectedTab = tab;
    },

    /**
     * Set current Data from Handsontable
     * TODO: this method is a hardCode
     * @param data
     */
    setHotSettingsData(data: number[][]) {
      this.hotSettingsData = data;
    },

    /**
     * Set selected availability
     */
    setSelectedAvailability(value: string) {
      this.selectedAvailability = value;
    },

    /**
     * Set selected period
     */
    setSelectedPeriod(value: ResourceGridTimeUnit) {
      this.selectedPeriod = value;
    },

    /**
     * Set selected values mode
     */
    setSelectedValuesMode(value: ResourceGridValuesMode) {
      this.selectedValuesMode = value;
    },

    /**
     * Set selected limit assigned resources
     */
    setSelectedLimitAssignedResources(value: number) {
      this.selectedLimitAssignedResources = value;
    },

    /**
     * Set mode
     */
    setMode(value: ResourceGridMode) {
      this.mode = value;
    },

    /**
     * Set delta config
     */
    setDeltaConfig(value: ResourceGridDeltaConfig) {
      this.deltaConfig = value;
    },

    /**
     * Expand or collapse all resources
     */
    setExpandAllResources(value: boolean) {
      this.expandAllResources = value;
    },

    /**
     * Set expanded resource types
     */
    setExpandedResourceTypes(value: ResourceGridExpandedResourceType[] | null) {
      this.expandedResourceTypes = value;
    },

    /**
     * Set resource filtered projects
     */
    setResourceFilteredProjects(value: number[]) {
      this.resourceFilteredProjects = value;
    },

    /**
     * Set overlay heatmap on the grid tab
     * @param value
     */
    setGridTabOverlay(value: number) {
      this.selectedGridHeatmapOverlay = value;
    },
  },
});
