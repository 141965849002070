import { defineStore } from 'pinia';
import { getSettings } from '@/api/settings';

import { GlobalSettings, GlobalPersonalization } from '@/models/Global';
import { ErrorData } from '@/models/Error';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    loading: false,
    settings: {
      StaticSettingsHash: '',
    } as GlobalSettings,
    personalization: {} as GlobalPersonalization,
    error: {
      message: '',
      action: '',
    } as ErrorData,
  }),

  actions: {
    /**
     * Set personalization
     * @param {GlobalPersonalization} personalization
     */
    setPersonalization(personalization: GlobalPersonalization) {
      this.personalization = personalization;
    },

    /**
     * Get user settings
     * @returns {void}
     */
    // eslint-disable-next-line consistent-return
    async getUserSettings(): Promise<void> {
      const { data: responseData } = await getSettings();

      // @TODO throw error via global error handler
      // eslint-disable-next-line no-console
      if (!responseData.Result) return console.log('[useGlobalStore/getUserSettings] ERROR: ');

      this.settings = responseData.Result;
    },

    /**
     * Set the error message
     */
    setError(action?: string, message?: string) {
      this.$patch((state) => {
        if (action) state.error.action = action;
        if (message) state.error.message = message;
      });
    },

    /**
     * Clear the error message
     */
    clearError() {
      this.$patch((state) => {
        state.error = {
          action: '',
          message: '',
        };
      });
    },
  },
});
