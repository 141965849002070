
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapState } from 'pinia';
import { useQueueStore } from '@/store/queue';
import { useModelStore } from '@/store/model';
import { MulticallParameters } from '@/api/multicall';
import { ModelHistoryData } from '@/models/ModelHistory';
import { useProjectStore } from '@/store/projects';
import { ProjectViewData } from '@/models/Project';
import { ModelUserData } from '@/models/ModelUser';

import html2canvas from 'html2canvas';

import { base64ToPdf, jsonToXLSX } from '@/utils/file.util';
import { getDateFromDiff, getDateWithMonth, getFullDateTime } from '@/utils/date.util';
import { useUserStore } from '@/store/users';

@Component({
  computed: {
    ...mapState(useModelStore, ['history', 'consolidatedHistory']),
    ...mapState(useUserStore, ['users']),
    ...mapState(useProjectStore, ['filteredProjects']),
  },
  methods: mapActions(useQueueStore, ['addGetter', 'addModification', 'sendQueue']),
})
export default class HeaderWrapperHistory extends Vue {
  loading = false;

  activeTab = 0;

  history!: ModelHistoryData[];

  consolidatedHistory!: ModelHistoryData[];

  filteredProjects!: ProjectViewData;

  users!: ModelUserData[];

  toolbarContentClass = 'header-wrapper-history__inner-toolbar_content d-flex justify-space-between align-center';

  imageData = null

  historySet = []

  addGetter!: (action: string, params: MulticallParameters) => void;

  addModification!: (action: string, params: MulticallParameters) => void;

  sendQueue!: () => void;

  // Computed
  get stepsCount() {
    return this.history.length || 0;
  }

  get sortedFullHistory() {
    return (this.activeTab === 0 && this.fullInfoHistory
      .sort((
        a: { CreatedOn: Date; },
        b: { CreatedOn: Date; },
      ) => new Date(b.CreatedOn) - new Date(a.CreatedOn)))
    || this.fullInfoHistory.reverse();
  }

  get fullInfoHistory() {
    const historyList = (this.activeTab === 0 && this.history)
      || this.consolidatedHistory;
    return historyList.map((historyItem) => {
      const isCopy = historyItem.Type === 'CloneProject';
      const relatedProject = this.filteredProjects
        .find((project: { Id: number; }) => (!isCopy
          ? historyItem.ProjectId === project.Id
          : historyItem.NewProjectId === project.Id));
      const relatedUser = this.users.find((user) => user.Id === historyItem.CreatedByUserId);
      return {
        ...historyItem,
        EndDate: relatedProject?.EndDate,
        StartDate: relatedProject?.StartDate,
        HasAllocations: relatedProject?.HasAllocations,
        IsPartial: relatedProject?.IsPartial,
        Name: relatedProject?.Name,
        RangeEndDate: relatedProject?.RangeEndDate,
        CreatedByUserName: relatedUser?.Name,
        CreatedOn: historyItem.CreatedOn ? getFullDateTime(historyItem.CreatedOn?.toString()) : '',
        ...(isCopy ? {
          OldProjectId: historyItem.ProjectId,
          OldProjectName: this.getProjectByID(historyItem.ProjectId)?.Name || '',
          StartDate: getDateWithMonth(
            getDateFromDiff(historyItem.StartDate).toISOString(),
          ),
          EndDate: getDateWithMonth(
            getDateFromDiff(historyItem.EndDate).toISOString(),
          ),
        } : {}),

      };
    });
  }

  // Methods
  /**
   * Revert chosen User's step in the history
   * @param id current ID of the history item
   */
  async revertStep(id: number) {
    this.addModification('UndoChange', { Id: id });
    await this.fetchMainInfo();
  }

  /**
   * Revert all User's steps in the history
   */
  async revertAllSteps() {
    this.addModification('UndoAllChanges', { ModelId: Number(this.$route.params.id) });
    await this.fetchMainInfo();
  }

  /**
   * Convert html element to pdf file
   * @param selector
   */
  async htmlToPdf(selector: string) {
    const historyMenu = document
      .querySelector(selector)
      ?.cloneNode(true);

    if (historyMenu) {
      historyMenu.style.zIndex = '-1';
      document.querySelector('#app')?.appendChild(historyMenu);
      const historyMenuList = document
        .querySelectorAll(selector);
      const lastList = historyMenuList[historyMenuList.length - 1];
      const contentList = lastList
        .querySelector('.header-wrapper-history__inner-content');
      if (contentList) {
        contentList.style.maxHeight = 'initial';
      }
      if (lastList) {
        this.imageData = await html2canvas(lastList);
        base64ToPdf(this.imageData, 'history', lastList.clientWidth, lastList.clientHeight);
        lastList.remove();
      }
    }
  }

  /**
   * Format options to export history data into XLSX format
   */
  async handleXLSXExport() {
    // Format history data into rows
    const rows = this.sortedFullHistory.map((history) => {
      const delta = `${history.Delta} Days`;
      const change = history.DeltaPercentage
        ? `${delta}, ${Math.round(history.DeltaPercentage)}%`
        : ((history.Delta && delta) || '');
      return {
        project: {
          v: history.Name,
          t: 's',
          s: {
            alignment: { wrapText: true },
          },
        },
        partial: (history.IsPartial && 'Yes') || 'No',
        resource: '',
        task: '',
        action: this.getActionTitle(history.Type, history.Delta),
        change,
        score: history.NewScore,
        created: history.CreatedOn,
        creator: history.CreatedByUserName,
      };
    });

    // Set headers & apply styles to them
    const headers = ['Project', 'Partial', 'Resource', 'Task', 'Action', 'Change', 'Score', 'Created', 'Creator'];
    const headersStyles = {
      t: 's',
      s: {
        fill: { fgColor: { rgb: 'E4E4E4' } },
        alignment: { horizontal: 'center' },
      },
    };

    // Calculate columns width & rows height
    const maxColumnWidthList: { wch: number; }[] = [];
    Object.keys(rows[0]).forEach((key, index) => {
      if (index === 0) {
        maxColumnWidthList.push({ wch: 25 });
      } else {
        const maxWidth = rows.reduce((acc, row) => Math.max(acc, row[key]?.length), 10);
        maxColumnWidthList.push({ wch: maxWidth });
      }
    });
    const options = {
      rows,
      headers,
      fileName: 'history',
      workBookName: 'Excel Export',
      maxColumnWidthList,
      headersStyles,
    };
    jsonToXLSX(options);
  }

  /**
   * Get icon by defined type of action
   * @param action
   * @param delta
   */
  getIconByAction = (action: string, delta: number) => {
    const actionList = {
      Shift: delta >= 0 ? 'arrow-right-bold' : 'arrow-left-bold',
      ExpandContract: delta >= 0 ? 'arrow-left-right-bold' : 'arrow-collapse',
      CloneProject: 'checkbox-multiple-blank-outline',
      Include: 'arrow-left-top-bold',
      Exclude: 'folder-remove',
      Link: 'link',
      default: '',
    };
    return actionList[action] ? actionList[action] : actionList.default;
  };

  getActionTitle = (action: string, delta: number) => {
    const actionList = {
      ExpandContract: delta >= 0 ? 'Expand' : 'Contract',
      default: action,
    };
    return actionList[action] ? actionList[action] : actionList.default;
  }

  getProjectByID(id: number) {
    return this.filteredProjects
      .find((project: { Id: number; }) => id === project.Id) || {};
  }

  /**
   *  Fetch info which needed to work with
   */
  async fetchMainInfo() {
    this.loading = true;

    this.addGetter('GetModelHistory', { Id: Number(this.$route.params.id) });
    this.addGetter('GetModelConsolidatedHistory', { Id: Number(this.$route.params.id) });
    this.addGetter('GetModelVirtualProjects', { Id: Number(this.$route.params.id) });
    this.addGetter('GetModelProjects', { Id: Number(this.$route.params.id) });
    this.addGetter('GetProjects', {});
    this.addGetter('GetUsers', {});
    this.addGetter('GetModelScore', { Id: Number(this.$route.params.id) });

    await this.sendQueue();

    this.loading = false;
  }

  async created() {
    await this.fetchMainInfo();
  }
}
