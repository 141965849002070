import { defineStore } from 'pinia';
import {
  ProjectName,
  ProjectCustomField,
} from '@/models/Project';

const labelToName = (label: string) => {
  let result;

  switch (label) {
    case 'Project ID':
      result = 'id';
      break;
    default:
      result = label.toLowerCase().replace(' ', '_');
  }
  return result;
};

export const useProjectStore = defineStore('projects', {
  state: () => ({
    projects: [] as ProjectName[],
    customFields: [] as ProjectCustomField[],
    selectedCustomField: {} as ProjectCustomField,
  }),
  getters: {
    /**
     * Get projects only with name and sortable by Name
     */
    filteredProjects: (state) => state.projects
      .filter((project) => project.Name)
      .sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      }),

    /**
     * Get custom fields as Gantt columns
     */
    customFieldsAsGanttColumns: (state) => state.customFields
      .filter((field) => field.CustomFieldType !== 'String')
      .map((field) => ({
        id: field.Id,
        name: labelToName(field.Name), // field.Name.toLowerCase().replace(' ', '_'),
        label: field.Name,
        type: field.CustomFieldType,
        width: 160,
        sortDirection: 0,
        parameters: (field.EnumMembers || [])
          .map((m) => ({
            label: m,
            value: true,
          })),
      })) || [],

    /**
     * Get id visible projects for resources
     */
    resourceVisibleProjects: (state) => state.projects
      .map((project) => project.Id) || [],

    /**
     * Get custom fields by Enum type
     */
    customFieldsByEnum: (state) => state.customFields
      .filter((field) => field.CustomFieldType === 'Enum')
      .map((field) => ({
        ...field,
        text: field.Name,
        value: field.Name,
      }))
      .sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      }),
  },
  actions: {
    /**
     * Set projects list
     * @param projects
     */
    setProjects(projects: ProjectName[]) {
      this.projects = projects;
    },

    /**
     * Set project custom fields
     * @param customFields
     */
    setCustomFields(customFields: ProjectCustomField[]) {
      this.customFields = customFields;
    },

    /**
     * Set selected custom field
     * @param value
     */
    setSelectedCustomField(value) {
      this.selectedCustomField = value;
    },
  },
});
