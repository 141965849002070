
import Vue from 'vue';
import Component from 'vue-class-component';
import HeaderWrapper from '@/components/HeaderWrapper.vue';
import ErrorWrapper from '@/components/common/ErrorWrapper.vue';
import Footer from '@/components/Footer.vue';
import { registerSubscriptions } from './store/subscriptions';

@Component({
  components: {
    HeaderWrapper,
    ErrorWrapper,
    Footer,
  },
})
export default class App extends Vue {
  // eslint-disable-next-line class-methods-use-this
  created() {
    registerSubscriptions();
  }
}
