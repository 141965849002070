import { defineStore } from 'pinia';

export const useStaticStore = defineStore('static', {
  state: () => ({
    /** Tabs data for Projects top section */
    tabsProjects: [
      {
        label: null,
        value: 0,
        icon: {
          html: 'mdi-format-align-left',
          color: null,
        },
      }, {
        label: null,
        value: 1,
        icon: {
          html: 'mdi-format-list-group',
          color: null,
        },
      },
    ],
    tabsProjectsResource: [
      {
        label: null,
        value: 0,
        icon: {
          html: 'mdi-folder',
          color: null,
        },
      }, {
        label: null,
        value: 1,
        icon: {
          html: 'mdi-human-male',
          color: null,
        },
      },
    ],

    /** Tabs data for Resources bottom section */
    tabsResources: [
      {
        label: 'Grid',
        value: 'grid',
        icon: {
          html: 'mdi-table-of-contents',
          color: 'red accent-2',
        },
      }, {
        label: 'Graph',
        value: 'graph',
        icon: {
          html: 'mdi-chart-bar',
          color: 'green accent-4',
        },
      }, {
        label: 'Heat Map',
        value: 'heat_map',
        icon: {
          html: 'mdi-view-grid-outline',
          color: 'red accent-2',
        },
      }, {
        label: 'Cool Map',
        value: 'cool_map',
        icon: {
          html: 'mdi-view-grid-outline',
          color: '#20b9ed',
        },
      }, {
        label: 'Delta',
        value: 'delta',
        icon: {
          html: 'mdi-star-half-full',
          color: 'red accent-2',
        },
      },
    ],

    /** Tabs, where cell data with '%' */
    percentTabs: [
      'heat_map',
      'cool_map',
      'delta',
      'graph',
    ],

    /** Tabs, where cell data with '$' */
    costTabs: [
      'grid',
    ],

    /** Data for Values Mode filter in Subheader Resources */
    fullValuesModes: [{
      text: 'Time',
      value: 'Time',
    }, {
      text: 'Cost',
      value: 'Cost',
    }, {
      text: 'FTE',
      value: 'FTE',
    }],

    /** Data for Periods Filter in Subheader Resources */
    periods: [{
      text: 'Day',
      value: 'Day',
    }, {
      text: 'Week',
      value: 'Week',
    }, {
      text: 'Month',
      value: 'Month',
    }, {
      text: 'Quarter',
      value: 'Quarter',
    }],

    /** Common Simple options for Ratio Btns */
    ratioOptions: [{
      text: 'On',
      value: 1,
    }, {
      text: 'Off',
      value: 0,
    }],

    /** Data for Availability filter in Subheader Resources */
    availabilities: [{
      text: 'Net Availability',
      value: 'availability',
    }, {
      text: 'Deficit Only',
      value: 'deficit',
    }, {
      text: 'Surplus Only',
      value: 'surplus',
    }, {
      text: 'Allocation',
      value: 'allocation',
    }, {
      text: 'Net Capacity',
      value: 'capacity',
    }],

    customFieldList: [{
      text: 'Current Phase',
      value: 'current_phase',
    }, {
      text: 'Dataset Preference',
      value: 'dataset_preference',
    }],
  }),
});
