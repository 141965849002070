import { ErrorCode } from '@/models/Error';
import { ERROR_ACTIONS } from '@/constants/error.constants';

export const parseError = (code?: ErrorCode, message?: string) => {
  switch (code) {
    case 'NotLoggedIn':
      return { action: ERROR_ACTIONS.redirectToAuth, message: 'Need to login' };
    case 'ReloadNeeded':
      return { action: ERROR_ACTIONS.refresh, message: 'Some settings was changed recently. Please reload the page to see the newest version.' };
    case 'SyncRunning':
      return { message: 'Database Sync in Progress. Please wait for it to complete and try again later.' };
    case 'SupportedError':
      return { message };
    case 'T5ModelLocked':
      return { message: 'The model is locked. Someone is editing it at the moment.' };
    case 'ModelEditNeeded':
      return { action: ERROR_ACTIONS.redirectToEdit };
    case 'UnsupportedNoLog':
    case 'ReportsCacheLost':
    default:
      return { message: 'An error occurred on the server.' };
  }
};
