import { useProjectStore } from '@/store/projects';
import { useModelStore } from '@/store/model';
import { useGanttStore } from '@/store/gantt';

/**
 * Register store subscriptions
 * @returns {void}
 */
export const registerSubscriptions = () => {
  const projectsStore = useProjectStore();
  const modelStore = useModelStore();
  const ganttStore = useGanttStore();

  modelStore.$subscribe((mutation, state) => {
    ganttStore.setTasks(
      projectsStore.projects,
      state.projects,
      projectsStore.customFields,
      state.projectCustomFieldValues,
      state.selectedTimelineProjectsIDs,
    );
  });
};
