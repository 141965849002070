import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    redirect: '/model-list',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/model-list',
    name: 'modelList',
    component: () => import('../views/ModelListView.vue'),
  },
  {
    path: '/model-details/:id',
    name: 'modelDetails',
    component: () => import('../views/ModelDetailsView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
