import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;
const API_MODEL_PATH = process.env.VUE_APP_MODEL_PATH;
const API_SETTINGS_PATH = process.env.VUE_APP_SETTINGS_PATH;

export const getClient = (apiPath: 'MODEL' | 'SETTINGS') => axios.create({
  baseURL: API_URL + (apiPath === 'SETTINGS' ? API_SETTINGS_PATH : API_MODEL_PATH),
  withCredentials: true,
});
