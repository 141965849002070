
import { mapActions, mapState } from 'pinia';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { useGlobalStore } from '@/store/global';
import { ErrorData } from '@/models/Error';
import { ERROR_ACTION_NAMES, ERROR_TIMEOUT_MS, ERROR_AUTH_URL } from '@/constants/error.constants';

@Component({
  computed: {
    ...mapState(useGlobalStore, ['error']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['clearError']),
  },
})
export default class ErrorMessage extends Vue {
  error!: ErrorData

  clearError!: () => void

  errorActionNames: { [key: string]: string } = ERROR_ACTION_NAMES

  onActionClick(actionName?: string) {
    if (!actionName) return;
    switch (actionName) {
      case 'RedirectToAuth':
        window.location.href = ERROR_AUTH_URL;
        break;
      case 'RedirectToEdit':
        this.$router.push(`/model-update/${this.$route.params.id}`);
        break;
      case 'Refresh':
      default:
        window.location.reload();
    }
  }

  @Watch('error', { deep: true })
  onErrorChange() {
    setTimeout(() => {
      this.clearError();
    }, ERROR_TIMEOUT_MS);
  }
}
