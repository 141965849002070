import { defineStore } from 'pinia';
import {
  ProjectName, CustomFieldValuesArray, ProjectCustomField, CustomFieldValues,
  SelectedCustomField,
} from '@/models/Project';
import { TaskData } from '@/models/Task';
import { ModelProjectViewData } from '@/models/Model';
import {
  ModelGanttColumn,
  ModelGanttSorting,
  ModelGanttFiltering,
  ModelGanttFilterValues,
} from '@/models/ModelGantt';
import { useProjectStore } from './projects';

const labelToName = (label: string) => {
  let result;

  switch (label) {
    case 'Project ID':
      result = 'id';
      break;
    default:
      result = label.toLowerCase().replace(' ', '_');
  }
  return result;
};

export const useGanttStore = defineStore('gantt', {
  state: () => ({
    tasks: [] as TaskData[],
    openColumnsFlag: false,
    ganttAdditionalColumns: [] as ModelGanttColumn[],
    sortingParameters: {} as ModelGanttSorting,
    filterParameters: {} as ModelGanttFilterValues,
  }),

  actions: {
    /**
     * Set tasks
     * @param {ProjectName[]} projectNames,
     */
    setTasks(
      projects: ProjectName[],
      projectsData: ModelProjectViewData[],
      customFields: ProjectCustomField[],
      customFieldValuesArray: CustomFieldValuesArray[],
      selectedIDs: number[],
    ) {
      const customFieldValues = this.mapCustomFieldsValues(customFields, customFieldValuesArray);
      const tasks = projectsData.map((project: ModelProjectViewData) => ({
        ...customFieldValues.find((value) => value.id === project.Id),
        id: project.Id,
        text: projects.find((projectName: ProjectName) => projectName.Id === project.Id)?.Name || '',
        start_date: project.StartDate,
        end_date: project.EndDate,
        plannedStart: project.StartDate,
        plannedEnd: project.EndDate,
      }));
      tasks.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      this.tasks = tasks.filter((task) => selectedIDs.includes(task.id));
    },

    /**
     * Map custom fields values
     * @param {ProjectCustomField[]} customFields
     * @param {CustomFieldValuesArray[]} customFieldsValuesArray
     */
    mapCustomFieldsValues(
      customFields: ProjectCustomField[],
      customFieldsValuesArray: CustomFieldValuesArray[],
    ) {
      const customFieldValues = customFieldsValuesArray.map((customFieldsEntity) => {
        const values = customFields.reduce((a, b, index) => ({
          ...a,
          [labelToName(b.Name)]: customFieldsEntity.Values[index],
        }), {} as CustomFieldValues);
        return {
          ...values,
          id: customFieldsEntity.EntityId,
          is_proposal: values.is_proposal ? 'Yes' : 'No',
        };
      });
      return customFieldValues;
    },

    /**
     * Set open gantt left columns flag
     * @param {boolean} flag Open custom columns flag
     */
    setOpenColumnsFlag(flag: boolean) {
      this.openColumnsFlag = flag;
    },

    /**
       * Set gantt additional columns
       * @param {[object]} columns Custom additional Gantt columns
       */
    setGanttAdditionalColumns(columns: ModelGanttColumn[]) {
      if (columns.length > 2) return;
      this.ganttAdditionalColumns = columns;
    },

    /**
       * Set sorting parameters to additional column
       * @param {object} column Custom additional Gantt column
       */
    setSortingParametersForGanttColumn(column: ModelGanttColumn) {
      const {
        name: field,
        sortDirection: direction,
      } = column;

      this.sortingParameters = {
        field,
        direction,
      };
    },

    /**
       * Set filtering parameters to additional column
       * @param {object} item Parameters for Gantt column filtering
       */
    setFilteringParametersForGanttColumn(item: ModelGanttFiltering) {
      const { name, parameters } = item;
      if (typeof name === 'undefined') return;

      // calculate values
      // TODO: Replace types ANY
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const values = parameters.reduce((allValues: any, parameter: any) => {
        if (parameter.value === true) {
          return [
            ...allValues,
            parameter.label,
          ];
        }
        return allValues;
      }, []);
      this.$patch((state) => {
        state.filterParameters = {
          ...state.filterParameters,
          [name]: values,
        };
      });
    },

    /**
       * Set Gantt Additional Columns
       * TODO: move to ./gantt.ts
       * @param {modelProjectField[]} modelProjectFields
       */
    setGanttAdditionalColumnsFromModelProjectFields(modelProjectFields: SelectedCustomField[]) {
      const projectsStore = useProjectStore();
      const projectCustomFields = projectsStore.customFieldsAsGanttColumns;

      this.ganttAdditionalColumns = modelProjectFields.map((modelProjectField) => {
        const ganttColumn = projectCustomFields.find(
          (field) => field.id === modelProjectField.CustomFieldId,
        );

        let sortDirection;
        switch (modelProjectField.SortOrder) {
          case 'Asc':
            sortDirection = -1;
            break;
          case 'Desc':
            sortDirection = 1;
            break;
          default:
            sortDirection = 0;
        }

        return {
          ...ganttColumn,
          id: modelProjectField.CustomFieldId,
          sortDirection,
        };
      });
    },

    /**
       * Delete filter parameter by name
       * @param {string} filterName
       */
    deleteFilterParameterByName(filterName: string) {
      this.$patch((state) => {
        delete state.filterParameters[filterName];
      });
    },
  },
});
