import { defineStore } from 'pinia';
import { ModelUserData } from '@/models/ModelUser';

export const useUserStore = defineStore('users', {
  state: () => ({
    users: [] as ModelUserData[],
  }),

  actions: {
    /**
     * Set users
     * @param {ModelUserData[]} users
     */
    setUsers(users: ModelUserData[]) {
      this.users = users;
    },
  },
});
