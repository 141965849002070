import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';

/** Global Components */
import CommonLoader from '@/components/common/CommonLoader.vue';

/**
 * Vue-Resizer
 * https://vue-resizer.vicuxd.com/
 */
import {
  ResizeRow,
  DragCol,
} from 'vue-resizer';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.component('ResizeRow', ResizeRow);
Vue.component('DragCol', DragCol);

const pinia = createPinia();

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);

Vue.component('CommonLoader', CommonLoader);

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
