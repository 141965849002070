/* eslint-disable @typescript-eslint/no-explicit-any */
import { getClient } from '@/api/config';
import { parseError } from '@/api/error';
import { APIGetterNames } from '@/models/API';

// TODO: move all interfaces to @/models/API.ts
export interface MulticallParameters {
  Id?: number
  ModelId?: number
  Changes?: unknown
  CustomFieldIds?: number[]
  Config?: {
    ModelId: number
    TimeUnit: 'Month' | string
    // TODO: Replace types ANY
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ExpandedResourceTypes?: null | any
    ExpandAllResourceTypes: boolean
    ValuesMode: 'Time' | string
    DeltaConfig: {
      Baseline: boolean | string,
      ChangedRowsOnly: boolean | string,
    } | null
    ReplaceResourcePreviewConfig: null
    Mode: 'ResourceViewQuickLoad' | string
    ImpactProjectId: null
    ChangesPreview: null
    FilteredProjects: number[] | null
    VisibleProjects: number[] | null
  }
}

export interface MulticallAction {
  Action: APIGetterNames
  Parameters: MulticallParameters
}

export interface MulticallCache {
  Action?: APIGetterNames
}

export interface MulticallRequest {
  Cache: MulticallCache
  Getters: MulticallAction[]
  Modification: MulticallAction
}

export interface MulticallResponseResult {
  isSuccessfull: boolean
  // @TODO Add universal interface for getter response
  Result: any | null
  Exception?: any | null
}

export interface MulticallResponse {
  GetterResponses: MulticallResponseResult[]
  ModificationResponse: MulticallResponseResult
}

export interface MulticallErrorResponse {
  isError: boolean
  action?: string
  message?: string
}

const client = getClient('MODEL');

/**
 * Mapping the `multicall` API response to the desired format for use in the application state
 * @param getters
 * @param modification
 * @param response
 * @returns
 */
export const parseResponse = (
  getters: MulticallAction[],
  modification: MulticallAction,
  response: MulticallResponse,
) => {
  const gettersData = getters.map((getter: MulticallAction, i: number) => ({
    action: getter.Action,
    data: response.GetterResponses[i]?.Result,
    error: response.GetterResponses[i]?.Exception,
  }));
  const modificationData = response.ModificationResponse;

  // @TODO Add an interface for return value
  return { gettersData, modificationData };
};

/**
 * Send `multicall` request
 * @param getters
 * @param modification
 * @param staticHash
 * @returns AxiosInstance
 */
export const send = async (
  getters: MulticallAction[],
  modification: MulticallAction,
  staticHash: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await client.post('', {
    Request: {
      Cache: {},
      Getters: getters,
      Modification: modification?.Action ? modification : { Action: 'None', Parameters: {} },
    },
  }, {
    headers: {
      'x-sgt5-staticsettingshash': staticHash,
    },
  });

  if (response.data.IsSuccessful) return response;

  const { Key: key, Message: message } = response.data;
  return {
    isError: true,
    ...parseError(key, message),
  };
};
