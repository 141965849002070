/* eslint-disable import/no-cycle */
import { defineStore } from 'pinia';
import { get as _get } from 'lodash';
import {
  MulticallAction,
  MulticallParameters,
  send,
  parseResponse,
} from '@/api/multicall';

import { APIGetterNames } from '@/models/API';
import { useAPIGetters } from '@/api/APIGetters';
import { useGlobalStore } from './global';

export const useQueueStore = defineStore('queue', {
  state: () => ({
    getters: [] as MulticallAction[],
    modification: {} as MulticallAction,
  }),

  actions: {
    /**
     * Add getter action to the request queue
     * @param action
     * @param parameters
     */
    addGetter(action: string, parameters: MulticallParameters) {
      this.$patch((state) => {
        // check if this getter was already added to the queue and remove it
        const existingGetterIndex = state.getters.findIndex((getter) => getter.Action === action);
        if (existingGetterIndex !== -1) {
          state.getters[existingGetterIndex] = ({
            Action: action as APIGetterNames,
            Parameters: parameters,
          });
          return;
        }
        state.getters.push({
          Action: action as APIGetterNames,
          Parameters: parameters,
        });
      });
    },

    /**
     * Add modification action to the request queue
     * @param action
     * @param parameters
     */
    addModification(action: string, parameters: MulticallParameters) {
      this.$patch((state) => {
        state.modification = {
          Action: action as APIGetterNames,
          Parameters: parameters,
        };
      });
    },

    /**
     * Send a request queue to the API
     * @returns parsedResponse: { gettersData, modificationData }
     */
    async sendQueue() {
      const globalStore = useGlobalStore();
      const APIGetters = useAPIGetters();

      const staticHash = globalStore.settings?.StaticSettingsHash;

      const response = await send(this.getters, this.modification, staticHash);

      if (response.isError) {
        const { action, message } = response;
        globalStore.setError(action, message);
        return {};
      }

      const { data: responseData } = response;

      const parsedResponse = parseResponse(this.getters, this.modification, responseData.Result);
      const { gettersData } = parsedResponse;

      for (let i = 0; i < gettersData.length; i += 1) {
        const { action, data, error } = gettersData[i];
        const {
          mutation, pathToResponse,
        } = APIGetters[action];
        if (error) {
          globalStore.setError('', error.Message);
        }
        mutation(pathToResponse ? _get(data, pathToResponse) : data);
      }

      this.$reset();
      return parsedResponse;
    },
  },
});
