/* eslint-disable import/no-cycle */
import { useGlobalStore } from '@/store/global';
import { useModelStore } from '@/store/model';
import { useProjectStore } from '@/store/projects';
import { useResourceStore } from '@/store/resources';
import { useHandsontableStore } from '@/store/handsontable';
import { useUserStore } from '@/store/users';

export const useAPIGetters = () => {
  const globalStore = useGlobalStore();
  const projectsStore = useProjectStore();
  const resourcesStore = useResourceStore();
  const handsontableStore = useHandsontableStore();
  const modelStore = useModelStore();
  const usersStore = useUserStore();

  return {
    GetPersonalization: { // new
      pathToResponse: '',
      store: 'global',
      name: 'personalization',
      mutation: globalStore.setPersonalization,
    },

    // Projects
    GetProjects: {
      pathToResponse: 'UpdatedItems',
      store: 'projects',
      name: 'projects',
      mutation: projectsStore.setProjects,
    },
    GetProjectCustomFields: {
      pathToResponse: 'UpdatedItems',
      store: 'projects',
      name: 'customFields',
      mutation: projectsStore.setCustomFields,
    },

    // Resources
    GetResources: {
      pathToResponse: 'UpdatedItems',
      store: 'resources',
      name: 'resources',
      mutation: resourcesStore.setResources,
    },
    GetResourceCustomFields: { // new
      pathToResponse: 'UpdatedItems',
      store: 'resources',
      name: 'customFields',
      mutation: resourcesStore.setCustomFields,
    },

    // Model data
    GetModelViewDefinition: {
      pathToResponse: 'UpdatedEntity',
      store: 'model',
      name: 'viewDefinition',
      mutation: modelStore.setViewDefinition,
    },
    GetModelProjects: {
      pathToResponse: 'AllItems',
      store: 'model',
      name: 'projects',
      mutation: modelStore.setProjects,
    },
    GetModelProjectCustomFieldValues: {
      pathToResponse: 'AllItems',
      store: 'model',
      name: 'projectCustomFieldValues',
      mutation: modelStore.setProjectCustomFieldValues,
    },
    GetModelVirtualProjects: {
      pathToResponse: 'UpdatedItems',
      store: 'model',
      name: 'virtualProjects',
      mutation: modelStore.setVirtualProjects,
    },
    GetModelProjectFields: { // selected custom fields
      pathToResponse: 'AllItems',
      store: 'model',
      name: 'selectedCustomFields',
      mutation: modelStore.setSelectedCustomFields,
    },
    GetModelResourceGrid: {
      pathToResponse: 'Rows.AllItems',
      store: 'handsontable',
      name: 'resourceGrid',
      mutation: handsontableStore.setResourceGrid,
    },
    GetModelVirtualResources: {
      pathToResponse: 'UpdatedItems',
      store: 'model',
      name: 'virtualResources',
      mutation: modelStore.setVirtualResources,
    },
    GetModelMilestones: { // new
      pathToResponse: 'AllItems',
      store: 'model',
      name: 'milestones',
      mutation: modelStore.setMilestones,
    },
    GetModelHistory: {
      pathToResponse: 'UpdatedItems',
      store: 'model',
      name: 'history',
      mutation: modelStore.setHistory,
    },
    GetModelConsolidatedHistory: {
      pathToResponse: '',
      store: 'model',
      name: 'consolidatedHistory',
      mutation: modelStore.setConsolidatedHistory,
    },
    GetModelLinksHistory: { // new
      pathToResponse: 'AllItems',
      store: 'model',
      name: 'linksHistory',
      mutation: modelStore.setHistoryLinks,
    },
    GetModelScore: {
      pathToResponse: '',
      store: 'model',
      name: 'score',
      mutation: modelStore.setModelScore,
    },
    GetModelLockState: {
      pathToResponse: '',
      store: 'model',
      name: 'lockState',
      mutation: modelStore.setModelLockState,
    },

    // users
    GetUsers: {
      pathToResponse: 'UpdatedItems',
      store: 'user',
      name: 'users',
      mutation: usersStore.setUsers,
    },
  };
};
