/**
  * Format ending of number
  * e.g. 5.12012 => 5.12, 5.00 => 5
  * @param number
  */
export const formatNumber = (number: number) => {
  const numWithFormattedEnding = number % 1 === 0
    ? number
    : (((`${number.toFixed(2)}`).slice(-2) === '00' && Math.round(number))
      || number.toFixed(2));
  return numWithFormattedEnding;
};

/**
 * Cost number from 000's
 * e.g. 50000 => 5
 */
export const formatCostNumber = (number: number) => number / 1000;
