import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getDateWithDayOfWeek } from '@/utils/date.util';
import * as XLSX from 'xlsx-js-style';
import { XLSXOptions } from '@/models/ModelXLSX';

/**
 * Convert Base64 to pdf file
 * @param base64
 * @param fileName
 * @param initialWidth
 * @param initialHeight
 */
export const base64ToPdf = (
  base64: string,
  fileName: string,
  initialWidth: number,
  initialHeight: number,
) => {
  const coefficient = 1.15;
  const width = initialWidth / coefficient;
  const height = initialHeight / coefficient;
  const doc = new jsPDF('p', 'px', 'a4'); // eslint-disable-line new-cap
  doc.addImage(base64, 'png', 0, 0, width, height);
  const dateTime = getDateWithDayOfWeek();
  doc.save(`${fileName}_${dateTime}.pdf`);
};

/**
 * Convert JSON to XLSX file
 * @param options
 */
export const jsonToXLSX = (options: XLSXOptions) => {
  const {
    rows,
    fileName,
    workBookName,
    headers,
    headersStyles,
    rowHeight = 30,
    maxColumnWidthList,
  } = options || {};

  // Generate worksheet & workbook
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, workBookName);

  // Set headers & apply styles to them
  const styledHeaders = headers.map((title: string) => ({
    v: title,
    ...headersStyles,
  }));

  // Create worksheet with rows
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [styledHeaders],
    { origin: 'A1' },
  );
  worksheet['!cols'] = maxColumnWidthList;
  const rowHeightList = Array(rows.length + 1);
  rowHeightList.fill({ hpx: rowHeight });
  worksheet['!rows'] = rowHeightList;
  const dateTime = getDateWithDayOfWeek();

  // Create an XLSX file & try to save
  XLSX.writeFile(workbook, `${fileName}_${dateTime}.xlsx`, { compression: true });
};

/**
 * Create Table in pdf file by table data
 * @param fileName
 * @param orientation
 * @param table
 * @returns {void}
 */
export const tableDataToPdf = (
  fileName: string,
  orientation: 'p' | 'l',
  table: { data: { content: string | number, cellStyle: object }[][], headers: {
    content: string,
    colSpan: number,
  }[][] },
) => {
  const ySize = table.data.length * 16.6;
  const xSize = (table.headers?.[1] || table.headers[0]).length * 50;

  const doc = new jsPDF( // eslint-disable-line new-cap
    orientation,
    'px',
    [ySize, xSize],
  );

  autoTable(doc, {
    head: [...table.headers],
    body: [
      ...table.data,
    ],
    theme: 'grid',
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    margin: 20,
    useCss: true,
    headStyles: {
      halign: 'center',
      fillColor: '#dfedf0',
      textColor: '#7f7f7f',
      lineColor: '#e4e4e4',
      lineWidth: 0.2,
    },
    bodyStyles: {
      lineColor: '#e4e4e4',
      lineWidth: 0.2,
    },

    didParseCell: (HookData) => {
      const { section } = HookData;
      const { cell } = HookData;
      const { column } = HookData;

      if (section === 'head') return;

      const { styles: pdfCellStyles } = cell;

      const cellRawAsString = JSON.parse(JSON.stringify(cell.raw || null));

      if (!cellRawAsString) throw Error('No cell data from table.');

      const { cellStyle } = cellRawAsString || {};

      if (column.index === 0) pdfCellStyles.cellPadding = { left: cellStyle.paddingLeft, top: 5 };

      pdfCellStyles.textColor = cellStyle.color;
      pdfCellStyles.fontStyle = cellStyle.weight;
      pdfCellStyles.fillColor = cellStyle.fill;
    },

    didDrawPage: (HookData) => {
      /** Set Page Number */
      doc.setFont('helvetica');
      doc.setFontSize(10);
      doc.setTextColor('#BDBDBD');
      doc.text(`${HookData.table.pageCount}`, 30, 15, {
        align: 'right',
        lineHeightFactor: 0.8,
      });
    },
  });

  const dateTime = getDateWithDayOfWeek();

  doc.save(`${fileName}_${dateTime}.pdf`);
};

/**
 * Create Table in Xls file by table data
 * @param fileName
 * @param table
 * @returns {void}
 */
export const tableDataToXls = (
  fileName: string,
  table: { v: string | number, t: string, s?: {
    fill?: object,
    font?: object,
    border?: object,
    alignment?: object,
  } }[][],
  merge?: {
    s: { r: number, c: number },
    e: { r: number, c: number },
  }[] | null,
) => {
  const worksheet = XLSX.utils.aoa_to_sheet(table, { cellStyles: true });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, '1', true);

  const dateTime = getDateWithDayOfWeek();

  const rowHeightList = Array(table.length + 1);
  const colsWidthsList = Array(table[0].length);
  rowHeightList.fill({ hpx: 20 });
  colsWidthsList.fill({ wpx: 70 });
  colsWidthsList.unshift({ wpx: 155 });
  worksheet['!rows'] = rowHeightList;
  worksheet['!cols'] = colsWidthsList;

  if (merge) worksheet['!merges'] = merge;

  XLSX.writeFile(workbook, `${fileName}_${dateTime}.xlsx`, {
    compression: true,
    cellStyles: true,
  });
};
