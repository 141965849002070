export const ERROR_ACTIONS = {
  redirectToAuth: 'RedirectToAuth',
  refresh: 'Refresh',
  redirectToEdit: 'RedirectToEdit',
};

export const ERROR_ACTION_NAMES = {
  RedirectToAuth: 'Login',
  Refresh: 'Refresh the page',
  RedirectToEdit: 'Edit the model',
};

export const ERROR_TIMEOUT_MS = 10000;

export const ERROR_AUTH_URL = 'https://interactive.tempus-resource.com/dev/Home/Login';
