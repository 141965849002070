import { defineStore } from 'pinia';

/** Models */
import { ResourceName, ResourceCustomField } from '@/models/Resource';

export const useResourceStore = defineStore('resources', {
  state: () => ({
    resources: [] as ResourceName[],
    customFields: [] as ResourceCustomField[],
    expandedResourceTypes: [] as { ResourceTypes: string | [] }[],
  }),

  actions: {
    /**
     * Set resources
     * @param {ResourceName[]} resources
     */
    setResources(resources: ResourceName[]) {
      this.resources = resources;
    },

    /**
     * Set custom fields
     * @param {ResourceCustomField[]} customFields
     */
    setCustomFields(customFields: ResourceCustomField[]) {
      this.customFields = customFields;
    },
  },
});
